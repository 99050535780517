import { Formio } from 'vue-formio'
import NovtiFormioComponents from '@growingminds/novti-formio-components'
import i18n from '@/libs/i18n'
import store from '@/store'
import { v4 as randomUUID } from 'uuid'

// Use the components
Formio.use(NovtiFormioComponents)

/**
 * Updates the form of the current page
 *
 * @param {string} formId The form ID
 * @returns {Promise} Containing the response data
 */
export function updatePageForm(formId) {
  return store.dispatch('pages/updateCurrent', {
    formId,
  })
}

/**
 * Populates the form options
 *
 * @param {*} model
 */
export function populateFormOptions(model) {
  store.dispatch('forms/fetchAll')
    .then(() => {
      const forms = store.getters['forms/getAll']

      model.get('traits').where({ name: 'form' })[0].set('options', [
        ...forms
          .map(opt => ({ value: opt.id, name: opt.description }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ])
    })
    .catch(error => {
      console.debug(error)

      store.dispatch('notify', {
        body: i18n.t('Something went wrong retrieving the available forms'),
        variant: 'danger',
      })
    })
}

/**
 * Renders the form preview
 *
 * @param {*} editor
 * @param {*} model
 * @param {*} schema
 * @param {*} newValue
 */
export function renderPreview(editor, model, schema, newValue = null) {
  const previewElement = editor.Canvas.getDocument().getElementById('formio')
  const currentPage = store.getters['pages/getCurrent']

  // Check if a new value is set from the select box, otherwise use the already existing value from the currentPage.
  // On both empty. It will do nothing.
  editor.DomComponents.componentsById.formio.setAttributes({ form: newValue ?? currentPage.data.formId })

  Formio.createForm(previewElement, schema, {
    buttonSettings: {
      showCancel: false,
      showPrevious: false,
      showNext: false,
      showSubmit: false,
    },
  })

  // All child elements should be non-editable
  const updateAll = model => {
    model.set({ editable: false })
    model.get('components').each(model => updateAll(model))
  }

  updateAll(model)
}

/**
 * Shows a notice when not form is selected
 * by appending it to the given element
 *
 * @imporant WIP prototype!
 * This can be done smarter! E.g. by using
 * `model.get('traits').where({ name: 'form' })[0]`
 *
 * @param {*} el The current element
 */
export function showNoFormNotice(el, model) {
  const label = document.createElement('label')

  label.setAttribute('for', 'form-options')
  label.innerHTML = i18n.t('Select a form<br>')

  const selectList = document.createElement('select')

  selectList.id = 'form-options'
  selectList.addEventListener('change', event => {
    model.setAttributes({ form: event.target.value })
  })

  label.appendChild(selectList)
  el.appendChild(label)

  store.dispatch('forms/fetchAll')
    .then(() => {
      const forms = store.getters['forms/getAll']
        .sort((a, b) => a.description.localeCompare(b.description))

      for (let i = 0; i < forms.length; i += 1) {
        const option = document.createElement('option')

        option.value = forms[i].id
        option.text = forms[i].description

        selectList.appendChild(option)
      }
    })
    .catch(error => {
      console.debug(error)

      store.dispatch('notify', {
        body: i18n.t('Something went wrong retrieving the available forms'),
        variant: 'danger',
      })
    })
}

/**
 * Migrate the old JSON structure to the new one
 *
 * @param {Object} json The old JSON structure
 * @param {Object} styles The old styles structure
 *
 * @returns {Object} The new JSON structure
 */
export const migrateJsonStructure = (json, styles) => {
  const data = {
    assets: [],
    styles: [],
    pages: [
      {
        id: randomUUID(),
        frames: [
          {
            id: randomUUID(),
            component: {
              type: 'wrapper',
              stylable: [
                'background',
                'background-color',
                'background-image',
                'background-repeat',
                'background-attachment',
                'background-position',
                'background-size',
              ],
              components: [],
              head: { type: 'head' },
              docEl: { tagName: 'html' },
            },
          },
        ],
      },
    ],
    symbols: [],
    dataSources: [],
  }

  // Helper function to recursively transform components
  const transformComponent = component => {
    // Convert classes to simple arrays
    const classes = component.classes?.map(c => (typeof c === 'object' ? c.name : c)) || []

    // Normalize the component structure
    const newComponent = {
      ...component,
      classes,
      components: component.components ? component.components.map(transformComponent) : [],
    }

    return newComponent
  }

  // Transform each top-level component in the old JSON into the new format
  data.pages[0].frames[0].component.components = json.map(transformComponent)
  data.styles = styles

  return data
}
