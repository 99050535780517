import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  BootstrapVueIcons,
} from 'bootstrap-vue'
import VueViewer from 'v-viewer'
import VueCompositionAPI from '@vue/composition-api'

// Local
import i18n from '@/libs/i18n'
import {
  db,
  auth,
  storage,
  themesDb,
  functions,
  clientThemesRootStorage,
} from '@/libs/firebase'
import store from './store'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { Auth0WrapperPlugin } from './plugins/auth0'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(Auth0WrapperPlugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
  db,
  auth,
  storage,
  themesDb,
  functions,
  clientThemesRootStorage,
})

// BSV Plugin Registration
Vue.use(VueViewer)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')

export default app
