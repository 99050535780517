import {
  doc,
  getDocs,
  deleteDoc,
  where,
  query,
  setDoc,
  writeBatch,
} from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    list: [],
    current: [],
  },
  getters: {
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getById: state => id => state.list.find(el => el.id === id),
    getCurrent: state => state.current.map(docRef => ({ ...docRef.data(), id: docRef.id })),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      const { docs } = payload

      state.list = docs
    },
    SET_CURRENT(state, payload) {
      const { docs } = payload

      state.current = docs
    },
  },
  actions: {
    /**
     * Creates a new document in the Firestore collection using the provided payload.
     *
     * @param {Object} payload - The data to store in the new document.
     *
     * @returns {Promise<DocumentReference>}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        const { tagsResources } = tenantCtx()

        const docRef = doc(tagsResources)

        setDoc(docRef, payload)
          .then(() => {
            resolve(docRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Fetches all revisions of a document by its collection name and reference ID.
     *
     * @param {Object} context - Vuex context object.
     * @param {Function} context.commit - Vuex commit function to update the store.
     * @param {Object} params - Object containing collection and document ID.
     * @param {string} params.collection - The Firestore collection name.
     * @param {string} params.id - The ID of the document to retrieve revisions for.
     *
     * @returns {Promise<DocumentSnapshot[]>}
     */
    fetchForCurrent({ commit }, { collection, id }) {
      return new Promise((resolve, reject) => {
        const { tagsResources } = tenantCtx()
        const q = query(tagsResources, where('collection', '==', collection), where('resourceId', '==', id))

        getDocs(q)
          .then(querySnapshot => {
            commit('SET_CURRENT', querySnapshot)

            resolve(querySnapshot.docs)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Fetches all documents from the Firestore collection that match a given tag ID.
     *
     * @param {Object} context - Vuex context object.
     * @param {Function} context.commit - Vuex commit function to update the store.
     * @param {string} id - The tag ID used to filter documents.
     *
     * @returns {Promise<void>}
     */
    fetchByTagId({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { tagsResources } = tenantCtx()
        const q = query(tagsResources, where('tagId', '==', id))

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', { docs: querySnapshot.docs, pagination: false })

            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Deletes a document from the Firestore collection based on the provided item object.
     *
     * @param {Object} item - Object containing the ID of the document to be deleted.
     *
     * @returns {Promise<DocumentReference>}
     */
    deleteItem({}, item) {
      return new Promise((resolve, reject) => {
        const { tagsResources } = tenantCtx()
        const { id } = item

        const docRef = doc(tagsResources, id)

        deleteDoc(docRef)
          .then(() => {
            resolve(docRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Deletes orphaned documents based on either `resourceId` or `tagId`.
     *
     * @param {Object} payload - Object containing filtering criteria.
     *
     * @returns {Promise<void>}
     */
    deleteOrphans({}, payload) {
      return new Promise((resolve, reject) => {
        const { tagsResources } = tenantCtx()

        let q

        if (payload.resourceId) {
          q = query(tagsResources, where('resourceId', '==', payload.resourceId))
        } else if (payload.tagId) {
          q = query(tagsResources, where('tagId', '==', payload.tagId))
        } else {
          reject()
        }

        getDocs(q)
          .then(querySnapshot => {
            if (querySnapshot.empty) {
              return resolve()
            }

            const batch = writeBatch(tagsResources.firestore)

            querySnapshot.forEach(document => batch.delete(document.ref))

            return batch.commit()
          })
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
