/*
   eslint-disable
   import/extensions,
   import/no-unresolved,
   import/prefer-default-export,
*/

// External
import {
  ref,
  uploadBytes,
} from 'firebase/storage'

// Local
import store from '@/store'
import i18n from '@/libs/i18n'
import { readBlob } from '@core/utils/utils'
import { migrateJsonStructure } from '../utils/utils'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

/**
 * Bootstraps the Storage Manager
 *
 * @param {*} editor - The editor object.
 * @param {*} insertedPage - The current page object.
 *
 * @returns {void}
 */
export function bootstrapStorageManager(editor, insertedPage) {
  const { pagesStorage } = tenantCtx()

  // Get the current page data
  let currentPage = insertedPage || store.getters['pages/getCurrent']

  // Defile all file paths
  const cssPath = `${currentPage.snapshot.id}/styles.css`
  const htmlPath = `${currentPage.snapshot.id}/index.html`
  const stylesPath = `${currentPage.snapshot.id}/styles.json`
  const translationsPath = `${currentPage.snapshot.id}/translations/`

  const storageManager = editor.Storage

  storageManager.add('google-firebase', {
    async load() {
      // Try to load the base layout first
      if (!currentPage.data.recipes) {
        if (
          (currentPage.data?.baseLayout !== null || currentPage.data?.baseLayout !== undefined)
          && currentPage.data?.baseLayout?.status === 'pending'
        ) {
          try {
            const componentBlob = await store.dispatch('themes/fetchActiveThemeFile', currentPage.data.baseLayout)
            const componentValue = await readBlob(componentBlob)

            editor.setComponents(componentValue)

            await editor.store()

            store.dispatch('pages/updateCurrent', {
              'baseLayout.status': 'complete'
            }).then()
              .catch(error => console.debug(error))

            return null
          } catch (error) {
            store.dispatch('pages/updateCurrent', {
              'baseLayout.status': 'error',
              'baseLayout.errorMessage': error,
            }).then()
              .catch(error => console.debug(error))

            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong loading the base layout. Ignoring...'),
            })
          }
        }
        
        return null
      }

      // NOTE: GrapeJS 0.19.4+ uses a new storage mechanism
      // https://github.com/GrapesJS/grapesjs/releases/tag/v0.19.4
      if (!currentPage.data.recipes['novti-recipe-data']) {
        console.debug('NOTE: Migrating old recipe structure to new')

        return migrateJsonStructure(
          JSON.parse(currentPage.data.recipes['novti-recipe-components']),
          JSON.parse(currentPage.data.recipes['novti-recipe-styles']),
        )
      }

      return JSON.parse(currentPage.data.recipes['novti-recipe-data'])
    },

    /**
     * Stores the data in Google Cloud Storage and Firestore
     */
    async store() {
      const uploads = []

      // Get the current page data if the data has been edited during the opening of the page builder
      // and the execution of the store command
      currentPage = insertedPage || store.getters['pages/getCurrent']

      // Let us get the selected form ID to store it under `formId` as well
      const formId = editor.DomComponents.componentsById.formio?.getAttributes()?.form ?? null
      const counterId = editor.DomComponents.componentsById.counter?.getAttributes()?.counter ?? null
      const {
        'supported-languages': supportedLanguages = null,
        language = null,
        translations = null,
      } = editor.DomComponents.componentsById['content-translator']?.getAttributes() || {}

      if (formId) {
        const form = await store.dispatch('forms/fetchById', formId)

        if (formId && form?.data()?.deletedAt) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong saving the page. Selected form does not exists'),
            variant: 'danger',
          })

          return
        }
      }

      // If a counter is attached to the page, Inject the campaignCode into the page.
      if (counterId) {
        const counter = await store.dispatch('counters/fetchById', counterId)

        if (counter?.data()?.deletedAt) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong saving the page. Selected counter does not exists'),
            variant: 'danger',
          })

          return
        }

        // Only inject the campaignCode if it is filled in the counter.
        if (counter.data().campaignCode) {
          currentPage.data = {
            ...currentPage.data,
            campaignCode: counter.data().campaignCode,
          }
        }
      }

      // Get the CSS from the editor's CSS rules as workaround to avoid missing CSS issue.
      // See https://gitlab.com/growingminds/internal/novti-admin/novti-admin-vuejs/-/issues/111
      const rulesToCSS = editor.Css.getRules().map(rule => rule.toCSS()).join('')

      prepareStorage(rulesToCSS, 'text/css', ref(pagesStorage, cssPath), uploads)
      prepareStorage(editor.getCss(), 'application/json', ref(pagesStorage, stylesPath), uploads)
      prepareStorage(editor.getHtml(), 'text/html', ref(pagesStorage, htmlPath), uploads)

      if (translations) {
        Object.keys(translations).forEach(lang => {
          prepareStorage(JSON.stringify(translations[lang]), 'application/json', ref(pagesStorage, `${translationsPath}/${lang}.json`), uploads)
        })
      }

      // Prepare Firestore payload.
      // Doing this we avoid storing `data` as it might contain incomplete CSS.
      const recipes = {
        'novti-recipe-css': rulesToCSS,
        'novti-recipe-html': editor.getHtml(),
        'novti-recipe-data': JSON.stringify(editor.getProjectData()),
      }

      await Promise.all(uploads)
        .then(() => {
          store.dispatch('pages/updateCurrent', {
            ...currentPage.data,
            recipes,
            formId,
            counterId,
            language,
            supportedLanguages,
            translations,
          })
        })
        .catch(error => console.debug(error))
    },
  })

  /**
   * Prepare files to upload to Cloud Storage
   *
   * @param {Object} data
   * @param {string} type The type of the headers e.g. application/json
   * @param {Object} ref  The reference for the Cloud Storage
   * @param {Array} uploads The list of items to upload
   *
   * @returns {void}
   */
  const prepareStorage = (item, type, reference, uploads) => {
    const data = new Blob([item], { type })

    uploads.push(uploadBytes(reference, data))
  }
}
