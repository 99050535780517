import i18n from '@/libs/i18n'

export default [
  {
    path: '/tags/list',
    name: 'tags-list',
    component: () => import('@/views/tags/tags-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Tags'),
      action: 'read',
      resource: 'tags',
    },
  },
  {
    path: '/tags/:id/view',
    name: 'tags-view',
    component: () => import('@/views/tags/tags-view/View.vue'),
    meta: {
      store: {
        subscribe: 'tags/SET_CURRENT',
        retrieve: 'tags/getCurrent',
      },
      pageTitle: i18n.t('Tags'),
      action: 'read',
      resource: 'tags',
      breadcrumb: [
        {
          text: i18n.t('Tags'),
          to: '/tags/list',
        },
        {
          text: '',
          field: 'key',
          active: true,
        },
        {
          text: i18n.t('View'),
          active: true,
        },
      ],
    },
  },

]
