import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  setDoc,
} from 'firebase/firestore'
import { constructQuery } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import store from '@/store'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    list: [],
    current: null,
  },
  getters: {
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getById: state => id => state.list.find(el => el.id === id),
    getCurrent: state => state.current,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      const { docs } = payload

      state.list = docs
    },
    SET_CURRENT(state, payload) {
      state.current = ({ ...payload.data(), id: payload.id })
    },
  },
  actions: {
    /**
     * Retrieves all documents from the Firestore collection, ordered by `updatedAt` in descending order.
     * Commits the retrieved documents to the store.
     *
     * @param {Object} context - Vuex context object.
     * @param {Function} context.commit - Vuex commit function to update the store.
     *
     * @returns {Promise<DocumentSnapshot[]>}
     */
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { tags } = tenantCtx()

        const q = constructQuery(tags, 'updatedAt', {
          order: 'desc',
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', { docs: querySnapshot.docs })

            resolve(querySnapshot.docs)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Retrieves a document from the Firestore collection using its ID.
     *
     * @param {string} id - The ID of the document to fetch.
     *
     * @returns {Promise<DocumentSnapshot>}
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { tags } = tenantCtx()
        const docRef = doc(tags, id)

        getDoc(docRef)
          .then(docSnapshot => {
            resolve(docSnapshot)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Fetches a document by its ID and sets it as the current item in the store.
     *
     * @param {Object} context - Vuex context object.
     * @param {Function} context.dispatch - Vuex dispatch function.
     * @param {Function} context.commit - Vuex commit function to update the store.
     * @param {string} id - The ID of the document to fetch.
     *
     * @returns {Promise<DocumentSnapshot>}
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)

            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Creates a new document in the Firestore collection with the given payload.
     *
     * @param {Object} payload - The data to store in the new document.
     *
     * @returns {Promise<DocumentReference>}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        const { tags } = tenantCtx()

        const docRef = doc(tags)

        setDoc(docRef, payload)
          .then(() => {
            resolve(docRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Updates an existing document in the Firestore collection with the given payload.
     * Notifies the user of the result and refreshes the current document in the store.
     *
     * @param {Object} context - Vuex context object.
     * @param {Function} context.dispatch - Vuex dispatch function.
     * @param {Object} payload - The updated data, including the document ID.
     * @param {string} payload.id - The ID of the document to update.
     *
     * @returns {Promise<boolean>}
     */
    update({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const { tags } = tenantCtx()
        const docRef = doc(tags, payload.id)

        updateDoc(docRef, payload)
          .then(() => {
            store.dispatch('notify', { body: i18n.t('Tag has been updated') })

            dispatch('fetchCurrent', payload.id)
            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the Tag'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Deletes a document from the Firestore collection and removes orphaned tag resources.
     *
     * @param {Object} data - The data object containing the document ID to be deleted.
     * @param {string} data.id - The ID of the document to delete.
     *
     * @returns {Promise<DocumentReference>}
     */
    deleteItem({}, data) {
      return new Promise((resolve, reject) => {
        const { tags } = tenantCtx()

        const { id } = data

        const docRef = doc(tags, id)

        deleteDoc(docRef)
          .then(() => {
            store.dispatch('tagsResources/deleteOrphans', { tagId: id })

            resolve(docRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
