import rteExtension from 'grapesjs-rte-extensions'
import presetWebpage from 'grapesjs-preset-webpage'
import basicBlocks from 'grapesjs-blocks-basic'

export default function pluginsOpts() {
  return {
    pluginsOpts: {
      [basicBlocks]: {
        blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video', 'map', 'slider'],
      },
      [presetWebpage]: {
        blocks: ['link-block', 'quote', 'text-basic'],
        formsOpts: false,
        modalImportTitle: 'Import Template',
        modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
        modalImportContent(editor) {
          return `${editor.getHtml()}<style>${editor.getCss()}</style>`
        },
      },
      [rteExtension]: {
        // default options
        base: {
          bold: true,
          italic: true,
          underline: true,
          strikethrough: true,
          link: true,
        },
        fonts: {
          fontName: true,
          fontColor: false,
          hilite: false,
        },
        format: {
          heading1: true,
          heading2: true,
          heading3: true,
          heading4: false,
          heading5: false,
          heading6: false,
          paragraph: true,
          clearFormatting: true,
        },
        subscriptSuperscript: false,
        indentOutdent: true,
        list: true,
        align: true,
        actions: false,
        undoredo: false,
        extra: false,
        darkColorPicker: false,
        maxWidth: '600px',
      },
    },
  }
}
