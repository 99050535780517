import storageManager from './managers/storage-manager'
import styleManager from './managers/style-manager'
import assetManager from './managers/asset-manager'
import blockManager from './managers/block-manager'
import deviceManager from './managers/device-manager'
import layerManager from './managers/layer-manager'
import traitManager from './managers/trait-manager'
import selectorManager from './managers/selector-manager'
import pluginsOpts from './plugins-opts'
import plugins from './plugins'
import canvas from './canvas'

export default function options() {
  return {
    // Rudimentary
    container: '#gjs',
    fromElement: true,
    showOffsets: true,
    height: '100%',
    protectedCss: '',
    allowScripts: true, // Soon to be configured through the admin panel

    // Config files
    ...styleManager,
    ...storageManager,
    ...layerManager,
    ...traitManager,
    ...selectorManager,
    ...assetManager,
    ...blockManager,
    ...deviceManager,
    ...pluginsOpts(),
    ...plugins,
    ...canvas(),
  }
}
