/*
  eslint-disable
  import/extensions,
  import/no-unresolved
*/

import i18n from '@/libs/i18n'

export default [
  {
    path: '/media',
    name: 'media-list',
    component: () => import('@/views/media/media-list/List.vue'),
    meta: {
      pageTitle: i18n.t('Media'),
      action: 'read',
      resource: 'forms',
    },
  },
  // {
  //   path: '/assets',
  //   name: 'assets-list',
  //   component: () => import('@/views/assets/assets-list/List.vue'),
  //   meta: {
  //     pageTitle: i18n.t('Assets'),
  //     action: 'read',
  //     resource: 'forms',
  //   },
  // },
]
