import Vue from 'vue'
import { remove } from 'lodash'

const STORAGE_ITEM = 'novti-search-columns'

export default {
  namespaced: true,
  state: {
    novtiSearch: JSON.parse(localStorage.getItem(STORAGE_ITEM)) ?? {
      columns: {},
    },
    shouldRetrigger: false,
  },
  getters: {
    getTableColumns: state => resource => state.novtiSearch?.columns[resource] ?? [],
    shouldRetrigger: state => state.shouldRetrigger,
  },
  mutations: {
    SET_TABLE_COLUMNS(state, payload) {
      const { resource, columns } = payload

      Vue.set(state.novtiSearch.columns, resource, columns)

      localStorage.setItem(STORAGE_ITEM, JSON.stringify(state.novtiSearch))
    },
    REMOVE_TABLE_COLUMN(state, payload) {
      const { resource, column } = payload

      remove(state.novtiSearch.columns[resource], columnField => columnField.key === column.key)

      localStorage.setItem(STORAGE_ITEM, JSON.stringify(state.novtiSearch))
    },
    RETRIGGER(state) {
      state.shouldRetrigger = !state.shouldRetrigger
    },
  },
}
