import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore'
import store from '@/store'
import i18n from '@/libs/i18n'
import { constructQuery } from '@core/utils/utils'
import { RevisionEventType } from '@core/revisions/revisions'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * Deletes a document from the Firestore collection.
     *
     * @param {Object} payload - The payload object containing the document ID to be deleted.
     *
     * @returns {Promise<DocumentReference>}
     */
    hardDelete({}, payload) {
      return new Promise((resolve, reject) => {
        const { uploads } = tenantCtx()
        const { id } = payload
        const docRef = doc(uploads, id)

        deleteDoc(docRef)
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_UPLOADS,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })

            resolve(docRef)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
