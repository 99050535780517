import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { confirmUnsavedLeave } from '@core/utils/utils'
import { authGuard, routeProtection } from '../auth'

import auth from './routes/auth'
import logs from './routes/logs'
import tags from './routes/tags'
import pages from './routes/pages'
import users from './routes/users'
import forms from './routes/forms'
import themes from './routes/themes'
import errors from './routes/errors'
import uploads from './routes/uploads'
import support from './routes/support'
import counters from './routes/counters'
import dashboard from './routes/dashboard'
import generalSettings from './routes/general-settings'
import accountSettings from './routes/account-settings'
import usageAndBilling from './routes/usage-and-billing'
import preferenceCenters from './routes/preference-centers'
import formConfigurations from './routes/form-configurations'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-analytics' } },
    ...auth,
    ...logs,
    ...tags,
    ...pages,
    ...users,
    ...forms,
    ...themes,
    ...errors,
    ...uploads,
    ...support,
    ...counters,
    ...dashboard,
    ...generalSettings,
    ...accountSettings,
    ...usageAndBilling,
    ...preferenceCenters,
    ...formConfigurations,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  await authGuard(to, _, next)

  if (to.name !== 'errors-not-authorized') {
    await routeProtection(to, _, next)
  }

  return next()
})

export default router
