import swal from 'sweetalert2'
import { getUnix } from '@/libs/date-format'
import router from '@/router'
import store from '@/store'
import i18n from './i18n'

/**
 * Wrapper for the success modal
 *
 * @param {Object} options                    Configurations for the alert
 * @param {string} options.title              Title that should be printed in the text alert
 * @param {Object} options.router             Router object
 * @param {string} [options.router.redirect]  Redirect to the route
 * @param {function} [options.fetch]          Function to fetch the data
 */
export const successAlert = options => {
  swal.fire({
    icon: 'success',
    title: i18n.t(options.alertTitle),
    text: i18n.t('The {title} has been {action}', { title: i18n.t(options.title), action: i18n.t(options.action) }),
    customClass: {
      confirmButton: 'btn btn-success',
    },
  }).then(() => {
    if (options?.router?.redirect) {
      router.push({ name: options.router.redirect })
    }

    if (options?.fetch) {
      options.fetch()
    }
  })
}

/**
 * Wrapper for the error modal
 *
 * @param {Object} options        Configurations for the alert
 * @param {string} options.title  Title that should be printed in the text alert
 */
export const errorAlert = options => {
  swal.fire('Error', i18n.t('Something went wrong deleting the {title}', { title: i18n.t(options.title) }), 'error')
}

/**
 * Wrapper for the confirm modal
 *
 * @param {Object} current  The current value of the form
 *
 * @param {Object} options                    Configurations for the alert
 * @param {string} options.title              Title that should be printed in the text alert
 * @param {string} options.text               Text of the alert
 * @param {Object} options.router             Router object with settings
 * @param {string} options.router.path        Path to the route to submit the values
 * @param {string} [options.router.redirect]  Name of the route to redirect to
 * @param {function} [options.fetch]          Function to fetch data
 */
export const confirmDelete = (current, options) => {
  if (!options.title) options.title = 'item'

  swal.fire({
    title: i18n.t('Are you ABSOLUTELY sure?'),
    text: i18n.t('You are about to permanently delete this {title}. You won\'t be able to revert this.', { title: i18n.t(options.title) }),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: i18n.t('Yes, delete it!'),
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
  }).then(res => {
    // Define the current as ref style if it's not defined as reference
    const reference = current?.value ?? current

    if (res.isConfirmed) {
      reference.deletedAt = getUnix()

      store.dispatch(options.router.path, reference)
        .then(() => {
          successAlert({
            title: options.title,
            action: 'deleted',
            alertTitle: 'Deleted!',
            router: options.router,
            fetch: options.fetch,
          })
        })
    }
  }).catch(e => {
    errorAlert({ title: options.title })
  })
}

/**
 * Wrapper for the confirm modal
 *
 * @param {Object} options                    Configurations for the alert
 * @param {string} options.title              Title of the alert
 * @param {string} options.text               Text of the alert
 * @param {string} options.icon               Icon of the alert
 * @param {string} options.confirmButtonText  Confirm Button text of the alert
 * @param {Boolean} options.cancelButton      Boolean for showing cancelButton
 * @param {string} options.cancelButtonText   Cancel Button text of the alert
 */
 export const confirmModal = (options) => {
  return swal.fire({
    title: options.title,
    text: options.text,
    html: options.html,
    icon: options.icon,
    showCancelButton: options.cancelButton ?? true,
    confirmButtonText: options.confirmButtonText,
    cancelButtonText: options.cancelButtonText || i18n.t('Cancel'),
    customClass: {
      confirmButton: options.customClass?.confirmButton ?? 'btn btn-primary',
      cancelButton: options.customClass?.cancelButton ?? 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
  }).then(res => {
    return res.isConfirmed
  }).catch(() => {
    errorAlert({ title: options.title })
  })
}
