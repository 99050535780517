/* eslint-disable */
import parserPostCSS from 'grapesjs-parser-postcss'
import rteExtension from 'grapesjs-rte-extensions'
import webpage from 'grapesjs-preset-webpage'
import blockBasics from 'grapesjs-blocks-basic'

export default {
  plugins: [
    blockBasics,
    webpage,
    parserPostCSS,
    rteExtension,
  ]
}
